<template>
  <HeaderComponent /> <!-- 全局头部组件 -->
  <router-view />
  <FooterComponent /> <!-- 全局底部组件 -->
  <div class="overlay"></div>
</template>



<script>
import FooterComponent from './components/footerComponent/footerComponent.vue';
import HeaderComponent from './components/headerComponent/headerComponent.vue';


export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>

<style>
#app {
  font-family: Avenir;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 0px;
  padding-top: 0px; 
  padding-bottom: 0px; 
}


</style>
