<template>
  <section ref="serviceSection" class="service-section" :class="{ 'in-view': upperInView }">
    <!-- Header Section -->
    <div class="section-title">
      <span class="line"></span>
      <h1>{{ $t('serviceSection.title0') }}</h1>
      <span class="line"></span>
      <img src="@/assets/images/CompLogo.png" alt="Logo" class="background-logo">
    </div>
    
    <!-- Services Cards Row 1 -->
    <section ref="container1" class="about-section" :class="{ 'in-view': container1InView }">
      <div :class="['cards-container1', { 'animate__animated animate__fadeInLeft': container1InView }]">
        <!-- 卡片 1 -->
        <div class="card" :style="{ backgroundImage: `url(${require('@/assets/images/consulting1.jpg')})` }">
          <h2>{{ $t('serviceSection.title1') }}</h2>
          <ul>
          <li>{{ $t('serviceSection.subtitle1') }}</li>
          <li>{{ $t('serviceSection.subtitle2') }}</li>
          </ul>
          <router-link to="/multilingual-customer-support" class="learn-more">{{ $t('serviceSection.s3') }}</router-link>
        </div>
        <!-- 卡片 2 -->
        <div class="card" :style="{ backgroundImage: `url(${require('@/assets/images/consulting2.jpg')})` }">
          <h2>{{ $t('serviceSection.title2') }}</h2>
          <ul>
            <li>{{ $t('serviceSection.subtitle3') }}</li>
            <li>{{ $t('serviceSection.subtitle4') }}</li>
          </ul>
          <router-link to="/supplier-network-access" class="learn-more">{{ $t('serviceSection.s3') }}</router-link>
        </div>
      </div>
    </section>

    <!-- Services Cards Row 2 -->
    <section ref="container2" class="about-section" :class="{ 'in-view': container2InView }">
      <div :class="['cards-container2', { 'animate__animated animate__fadeInLeft': container2InView }]">
        <!-- 卡片 3 -->
        <div class="card" :style="{ backgroundImage: `url(${require('@/assets/images/consulting3.jpg')})` }">
          <h2>{{ $t('serviceSection.title3') }}</h2>
          <ul>
            <li>{{ $t('serviceSection.subtitle5') }}</li>
            <li>{{ $t('serviceSection.subtitle6') }}</li>
          </ul>
          <router-link to="/logistics-solution" class="learn-more">{{ $t('serviceSection.s3') }}</router-link>
        </div>
        <!-- 卡片 4 -->
        <div class="card" :style="{ backgroundImage: `url(${require('@/assets/images/consulting4.jpg')})` }">
          <h2>{{ $t('serviceSection.title4') }}</h2>
          <ul>
            <li>{{ $t('serviceSection.subtitle7') }}</li>
            <li>{{ $t('serviceSection.subtitle8') }}</li>
          </ul>
          <router-link to="/cost-effective-solution" class="learn-more">{{ $t('serviceSection.s3') }}</router-link>
        </div>
      </div>
    </section>
  </section>
</template>


<script>
export default {
  name: 'ServicesSection',
  data() {
    return {
      container1InView: false, // container1 的显示状态
      container2InView: false, // container2 的显示状态
    };
  },

  mounted() {
    // 创建观察器
    const result1 = this.createObserverForContainer('container1');
    const result2 = this.createObserverForContainer('container2');

    // 检查观察器创建是否成功
    if (result1 !== 0 || result2 !== 0) {
      console.error('Error setting up IntersectionObserver');
    }
  },

  methods: {
    // 针对单个容器的观察器创建函数
    createObserverForContainer(containerRef) {
      const options = {
        root: null, // 相对于视口
        threshold: 0.4, // 当元素进入视口 40% 时触发
      };

      // 获取容器的引用
      const target = this.$refs[containerRef];
      if (!target) {
        return -1; // 未找到目标容器
      }

      // 创建观察器
      const observer = new IntersectionObserver((entries, observer) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          this[`${containerRef}InView`] = true; // 当容器进入视口时，设置 inView 为 true
          observer.unobserve(entry.target); // 停止观察，避免重复触发
        }
      }, options);

      // 开始观察目标容器
      observer.observe(target);
      return 0; // 成功
    },
  },
};

</script>

<style scoped>
/* General Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.service-section {
  height:auto;
  width: auto;
  margin-top: 50px;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* 相对定位 */
  margin-bottom: 20px;
}

.background-logo {
  width: 80px; /* 调整Logo尺寸 */
  opacity: 0.1; /* 半透明 */
  position: absolute; /* 绝对定位，确保Logo在文字下方 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* 完全居中 */
  z-index: 0; /* 保持Logo在底层 */
}

.section-title h1 {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  position: relative; /* 保证文字位于Logo前面 */
  z-index: 1; /* 保持文字在Logo之上 */
  background: transparent; /* 背景透明 */
  padding: 0 15px; /* 给文字两侧留出空间 */
}

.section-title .line {
  flex-grow: 1;
  height: 2px;
  background-color: #c5d4e2; /* 线条颜色 */
  margin: 0 15px; /* 线条与文字的间距 */
  z-index: 1; /* 确保线条在Logo前显示 */
}


.section-title::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 80px;
  background-size: contain; /* 根据图片大小适应 */
  opacity: 0.1; /* 调整背景图的透明度 */
  z-index: 0; /* 确保背景图在文字后面 */
}

/* Cards Container */
.cards-container1, .cards-container2 {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
  padding: 20px;
  justify-content: center;
}

.card {
  opacity: 0;
  transform: translateY(20px); /* 初始位置略微偏下 */
  transition: opacity 0.6s ease, transform 0.6s ease; /* 定义过渡效果 */
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 300px;
  width: 600px;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  overflow: hidden; /* 防止伪元素溢出 */
  background: no-repeat;
  background-size: cover;
}

/* 使用伪元素为背景图片添加透明效果 */

.card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* 半透明的白色覆盖层 */
  z-index: 0; /* 放在最底层，用于覆盖背景图片 */
}


.card:hover {
  transform: translateY(-10px);
}

.card h2 {
  position: relative;
  justify-content: left; /* 水平居中对齐 h2 元素 */
  width: 100%; /* 确保 h2 的宽度占满父容器 */
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: center;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* 添加轻微的黑色阴影 */
}
.card ul {
  list-style: none;
  padding: 0;
  z-index: 3;
  flex-direction: column;
}

.card ul li {
  font-size: 1rem;
  color: #ffffff;
  display: flex;
  margin: 10px 0;
}

/* Custom bullet points */
.card ul li::before {
  content: "⦿";
  color: #ffffff;
  font-size: 1rem;
  margin-right: 10px;
}

.learn-more {
  font-size: 20px;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
  position: absolute;
  bottom: 20px;
  right: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5); /* 添加轻微的黑色阴影 */

}

.learn-more:hover {
  text-decoration: underline;
}

/*Animation*/
/* 当元素进入视口时添加动画类，并且显示内容 */
.in-view .cards-container1,
.in-view .cards-container2 {
  opacity: 1;
  visibility: visible;
}

.cards-container1, .cards-container2 {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease, visibility 0.6s ease;
}

/* 使用 nth-child 选择器为每个卡片设置不同的延迟时间 */
.cards-container1 .card:nth-child(1) {
  transition-delay: 0.5s;
}
.cards-container1 .card:nth-child(2) {
  transition-delay: 0.7s;
}
.cards-container1 .card:nth-child(3) {
  transition-delay: 0.9s;
}

.cards-container2 .card:nth-child(1) {
  transition-delay: 0.3s;
}
.cards-container2 .card:nth-child(2) {
  transition-delay: 0.5s;
}

/* 当卡片所在的容器有 'in-view' 类时，卡片变为可见 */
.in-view .card {
  opacity: 1;
  transform: translateY(0); /* 恢复原始位置 */
}

/* Responsive Adjustments */
@media (max-width: 761px) {
  /* 修改卡片容器的布局为单列 */
  .cards-container1, .cards-container2 {
    display: flex; /* 保持 flex 布局 */
    flex-direction: column; /* 改为纵向排列 */
    align-items: center; /* 使卡片在水平方向居中 */
    padding: 10px; /* 减少内边距以适应小屏幕 */
    margin: 0; /* 减少外边距 */
  }

    /* 添加容器之间的间距 */
    .cards-container1 {
    margin-bottom: 30px; /* 设置两个容器之间的底部间距 */
  }

  /* 修改卡片的样式使它们依次垂直排列 */
  .card {
    width: 90%; /* 占据大部分容器的宽度，留一些边距 */
    max-width: 500px; /* 设定最大宽度，防止过大 */
    margin-bottom: 20px; /* 为卡片之间增加一些间距 */
    opacity: 0; /* 初始状态不可见 */
    transform: translateY(20px); /* 初始位置略微偏下 */
    transition: opacity 0.6s ease, transform 0.6s ease; /* 添加过渡效果 */
  }

  /* 当卡片进入视口时的动画效果 */
  .card.in-view {
    opacity: 1; /* 变为可见 */
    transform: translateY(0); /* 滑入效果 */
  }


}

@media (min-width: 762px) and (max-width: 1024px) {
  /* 修改卡片容器 */
  .cards-container1, .cards-container2 {
    display: flex; /* 保持 flex 布局 */
    flex-wrap: wrap; /* 允许卡片换行 */
    justify-content: center; /* 居中对齐卡片 */
    gap: 20px; /* 增加卡片之间的间距 */
    padding: 10px; /* 减少内边距以适应中屏幕 */
    margin: 0; /* 减少外边距 */
  }

  /* 修改卡片的样式 */
  .card {
    width: calc(50% - 20px); /* 每行两张卡片，占容器的 50%，减去间距 */
    max-width: 500px; /* 设定最大宽度，防止过大 */
    margin-bottom: 20px; /* 为卡片之间增加一些间距 */
  }

  /* 使第三行只有一张卡片且居中 */
  .cards-container2 .card:nth-child(3) {
    width: 90%; /* 第三行的单个卡片宽度 */
    margin-left: auto; /* 自动设置左边距使其居中 */
    margin-right: auto; /* 自动设置右边距使其居中 */
  }
}

</style>
