<template>
    <div class="supplier-negotiation-container">
      <div class="container">
      <!-- Content Block -->
      <div class="content-block">
        <h1>{{ $t('ISNRM.s1') }}</h1>
        <p>{{ $t('ISNRM.s2') }}</p>
        <p>{{ $t('ISNRM.s3') }}</p>
      </div>
  
      <!-- Logistics Features Section -->
      <div class="features-block">
        <h3>{{ $t('ISNRM.s4') }}</h3>
        <p>{{ $t('ISNRM.s5') }}</p>
        <h3>{{ $t('ISNRM.s6') }}</h3>
        <p>{{ $t('ISNRM.s7') }}</p>
      </div></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SupplierNegotiation',
  };
  </script>
  
  <style scoped>
  .supplier-negotiation-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    background-color: #f7f7f7;
    text-align: center;
    align-items: center;
    padding: 100px 40px 100px; /* 增加顶部内边距，避免与 header 重叠 */
  }

  .supplier-negotiation-container::before {
  content: '';
  position: fixed; /* 背景图固定在屏幕上，而不是容器内 */
  top: 50%; /* 水平居中 */
  left: 50%; /* 垂直居中 */
  transform: translate(-50%, -50%); /* 通过 transform 将背景图真正居中 */
  width: 60%; /* 控制背景图的宽度 */
  height: 60%; /* 控制背景图的高度 */
  background: url('@/assets/images/CompLogo.png') center/contain no-repeat; /* 替换为你的背景图片路径 */
  opacity: 0.1; /* 控制背景图透明度 */
  z-index: 0; /* 确保背景图在内容后面 */
  pointer-events: none; /* 避免背景图阻挡点击事件 */
  }
  
  .container{
    width: 800px; /* 固定容器宽度 */
    margin-bottom: 40px;
    padding: 20px; /* 添加容器的内边距 */
    background-color: white; /* 如果需要为容器设置背景色 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 为容器添加阴影 */
    border-radius: 8px; /* 添加圆角 */
  }
  
  .content-block {
    max-width: 1000px;
    margin-bottom: 40px;
  }
  
  .content-block h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .content-block p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
  .features-block {
    max-width: 1000px;
  }
  
  .features-block h3 {
    font-size: 1.5rem;
    color: #e63946;
    margin-top: 20px;
  }
  
  .features-block p {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
  }
  </style>
  