// languageStore.js
import { defineStore } from 'pinia';
import { i18n, loadLocaleMessages } from '@/i18n'; // 导入 i18n 实例和加载方法

export const useLanguageStore = defineStore('language', {
  state: () => ({
    currentLocale: 'en', // 默认语言
  }),
  actions: {
    async toggleLanguage() {
      // Switch between English, Chinese, and French
      if (this.currentLocale === 'en') {
        this.currentLocale = 'zh';
      } else if (this.currentLocale === 'zh') {
        this.currentLocale = 'fr';
      } else {
        this.currentLocale = 'en';
      }
      await loadLocaleMessages(this.currentLocale); // 动态加载语言文件
      i18n.global.locale.value = this.currentLocale; // 设置 i18n 的语言
    },
  },
});
