<template>
  <footer class="footer">
    <!-- Main Content Section -->
    <div class="footer-content">
      <!-- Left Section: Company Info -->
      <div class="footer-left">
        <img src="@/assets/images/CompLogo2.png" alt="Company Logo" class="footer-logo">
        <h4>Horizontal Sourcing&reg;</h4>
        <p>{{ $t('footer.s1') }}</p>
      </div>

      <!-- Middle Section: Office Info -->
      <div class="footer-right">
        <div class = "contact1">
        <p><i class="icon-location"></i> {{ $t('footer.s2') }}</p>
        <p>{{ $t('footer.s3') }}</p>
      </div>
        <div class = "contact2">

        <p><i class="icon-mail-alt"></i> {{ $t('footer.s4') }}</p>
        <p>
  <a href="mailto:Nicky.Chen@horizontalsourcing.com">
    Nicky.Chen@horizontalsourcing.com <span class="no-underline">({{ $t('footer.s6') }})</span>
  </a>
</p>
       
<p>
  <a href="mailto:Robbie.Qu@horizontalsourcing.com">
    Robbie.Qu@horizontalsourcing.com <span class="no-underline">({{ $t('footer.s7') }})</span>
  </a>
</p>
        </div>
        <div class = "contact3">

        <p><i class="icon-phone"></i> {{ $t('footer.s5') }}</p>
        <p>({{ $t('footer.s6') }}) +86 152-0212-2632</p>
        <p>({{ $t('footer.s7') }}) +1 778-929-8986</p></div>
      </div>
    </div>

    <!-- Footer Bottom Section: Legal Links -->
    <div class="footer-bottom">
      <p class="left">© 2024 Horizontal Sourcing</p>
      <p class="right">Designed, Developed and Powered by ®Rong.</p>
    </div>
      <!-- <p>
        <a href="#">Terms & Conditions</a> | 
        <a href="#">Privacy Statement</a> | 
      </p> -->
  </footer>

  <transition name = fade>
    <ContactForm v-if="showForm" @close="showForm = false" />
</transition>
</template>

<script>
import ContactForm from '@/pages/Contact/ContactForm.vue'

export default {
  name: 'FooterComponent',
  components:{
    ContactForm
  },
  data() {
    return {
      showForm: false,
    };
  },
  methods: {
  toggleForm() {
    this.showForm = !this.showForm;
    console.log('Form toggled:', this.showForm); // 输出showForm的值

  },}
};
</script>

<style scoped>
/* Footer Styling */
.footer {
  background-color: #1a1a1a; /* 深灰色背景 */
  color: #ffffff; /* White text */
  padding: 10px 50px;
  padding-top: 50px;
  font-size: 14px;
  display: flexbox;
}

.footer-content {
  display: flex; /* 使用 flex 布局 */
  justify-content: space-between; /* 让子元素均匀分布 */
  align-items: center; /* 子元素垂直方向居中 */
  padding: 20px; /* 增加内边距 */
}

.contact2 a{
  color: white;
}


.footer-left,
.footer-right {
  flex: 1;                      /* 使左右两部分等分，占据相同宽度 */
  display: flex;
  flex-direction: column;       /* 元素垂直排列 */
  align-items: center;          /* 水平方向居中对齐 */
  text-align: center;           /* 使文本在容器中居中 */
  gap: 10px;                    /* 增加元素之间的间距 */
}


.footer-right .contact1,
.footer-right .contact2,
.footer-right .contact3 {
  width: 300px;                 /* 固定每个 contact 部分的宽度 */
  padding: 5px;
  text-align: left;             /* 文本左对齐 */
  margin-bottom: 5px;          /* 给每个联系部分增加一些底部间距 */
  box-sizing: border-box;       /* 确保内边距包含在宽度之内 */
}

.footer-right .contact1 p,
.footer-right .contact2 p,
.footer-right .contact3 p {
  margin: 5px 0;                /* 每个段落之间增加一些上下间距 */
}

a {
  color: white; /* 链接的颜色 */
  text-decoration: underline; /* 链接默认显示下划线 */
}

a span.no-underline {
  text-decoration: none; /* 去掉 (CHN) 部分的下划线 */
  display: inline-block; /* 避免继承父元素的下划线 */

}

.footer-left {
  display: flex;
  flex-direction: column; /* 垂直排列内容 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  text-align: center; /* 确保文字居中 */
  flex: 1;
  margin: 0 auto; /* 水平居中容器 */
}

.footer-logo {
  margin-top: 20px;
  width: 25%; /* 根据需要调整Logo大小 */
  height: auto;
}

.footer-left h4 {
  font-size: 24px; /* 调整字体大小 */
  margin-bottom: 10px;
}

.footer-left p {
  font-size: 16px; /* 调整字体大小 */
  margin: 5px 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;
  text-align: left;
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: center; /* 整个容器居中对齐 */
  justify-content: center; /* 垂直方向居中 */
  height: 100%; 
}

.footer-right p {
  text-align: left; /* 确保每个段落的文本左对齐 */
  width: 100%; /* 使段落充满容器宽度 */
  margin-bottom: 10px;

}

.footer-right p i {
  margin-bottom: 10px;
}

/* Bottom Section Styling */
.footer-bottom {
  display: flex;
  justify-content: space-between; /* 左右对齐 */
  align-items: center; /* 垂直居中 */
  padding: 5px 20px; /* 缩小内边距，比如调整为5px */
  margin-top: 5px; /* 进一步减少外边距，或完全移除 */
  border-top: 1px solid #ffffff; /* 分隔线 */
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
  color: #ffffff; /* 白色文字 */
}

.footer-bottom .left {
  text-align: left; /* 靠左对齐 */
}

.footer-bottom .right {
  text-align: right; /* 靠右对齐 */
}

/* Media query section */
/* 小屏幕 (如：手机) */
@media (max-width: 480px) {
  .footer {
    padding: 20px;
  }

  .footer-content {
    display: flex !important; /* 确保生效 */
    flex-direction: column !important; /* 确保纵向排列 */
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .footer-left, .footer-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer-right {
  display: flex;
  flex-direction: column; /* 垂直排列内容 */
  align-items: center; /* 水平居中对齐 */
  justify-content: center; /* 垂直方向居中对齐 */
  height: 100%; /* 让容器充满可用高度，以便居中对齐生效 */
  text-align: center; /* 文本居中对齐 */
  padding: 20px; /* 内边距可以根据需要调整 */
}

.footer-right p {
  width: 100%; /* 段落宽度占满父容器 */
  margin-bottom: 15px; /* 增加段落之间的间距 */
  text-align: center; /* 确保每个段落的文本在视觉上居中 */
  white-space: nowrap; /* 防止不必要的换行 */
  overflow-wrap: break-word; /* 必要时对长字符串进行换行 */
}

.footer-right p i {
  margin-right: 10px; /* 图标与文本之间的间距 */
  vertical-align: middle; /* 确保图标与文本垂直对齐 */
}


  .footer-bottom {
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直排列内容 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
  text-align: center; /* 确保文本居中 */
  padding: 5px 20px; /* 调整内边距 */
  margin-top: 5px; /* 进一步减少外边距，或完全移除 */
  border-top: 1px solid #ffffff; /* 分隔线 */
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
  color: #ffffff; /* 白色文字 */
}
.footer-bottom .right {
  text-align: center; /* 确保与父容器一致 */
  margin-top: 10px; /* (可选) 增加上下行之间的间距 */
}
}




/* 平板 (如：iPad) */
@media (min-width: 481px) and (max-width: 1024px) {
  .footer {
    padding: 20px;
  }

  .footer-content {
    display: flex !important; /* 确保生效 */
    flex-direction: column !important; /* 确保纵向排列 */
    align-items: center;
    text-align: center;
    gap: 20px;
  }

  .footer-left, .footer-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .footer-right {
  display: flex;
  flex-direction: column; /* 垂直排列内容 */
  align-items: center; /* 水平居中对齐 */
  justify-content: center; /* 垂直方向居中对齐 */
  height: 100%; /* 让容器充满可用高度，以便居中对齐生效 */
  text-align: center; /* 文本居中对齐 */
  padding: 20px; /* 内边距可以根据需要调整 */
}

.footer-right p {
  width: 100%; /* 段落宽度占满父容器 */
  margin-bottom: 15px; /* 增加段落之间的间距 */
  text-align: center; /* 确保每个段落的文本在视觉上居中 */
  white-space: nowrap; /* 防止不必要的换行 */
  overflow-wrap: break-word; /* 必要时对长字符串进行换行 */
}

.footer-right p i {
  margin-right: 10px; /* 图标与文本之间的间距 */
  vertical-align: middle; /* 确保图标与文本垂直对齐 */
}


  .footer-bottom {
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直排列内容 */
  justify-content: center; /* 水平居中对齐 */
  align-items: center; /* 垂直居中对齐 */
  text-align: center; /* 确保文本居中 */
  padding: 5px 20px; /* 调整内边距 */
  margin-top: 5px; /* 进一步减少外边距，或完全移除 */
  border-top: 1px solid #ffffff; /* 分隔线 */
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
  color: #ffffff; /* 白色文字 */
}
.footer-bottom .right {
  text-align: center; /* 确保与父容器一致 */
  margin-top: 10px; /* (可选) 增加上下行之间的间距 */
}
}


/* 桌面设备 */
@media (min-width: 1025px) {
  /* 适配桌面设备的样式 */
}


</style>
