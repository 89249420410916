<template>
  <div>
    <!-- Existing Section -->
    <section id="uppersec">
      <div class="hero-section">
        <!-- Right Text Section (Keep this to display the text) -->
        <div class="text-content animate-on-scroll">
          <h1>{{ $t('SNA.s1') }}</h1>
          <h2>
            {{ $t('SNA.s2') }}</h2>       </div>
        <div class="overlay"></div>
      </div>
    </section>

    <!-- New Section -->
    <section id="lowersec">
      <!-- Container 1: Image Left, Text Right -->
      <div class="new-section1 animate-on-scroll">
        <div class="image-content">
          <img src="@/assets/images/exposition.jpg" alt="DDP Service" class="image-item animate-on-scroll" />
        </div>
        <div class="text-content">
          <h3>{{ $t('SNA.s3') }}</h3>
          <p>
            {{ $t('SNA.s4') }} </p>
          <p>{{ $t('SNA.s5') }}</p>
        </div>
      </div>

      <!-- Container 2: Image Right, Text Left -->
      <div class="new-section2 animate-on-scroll">
        <div class="text-content">
          <h3>{{ $t('SNA.s6') }}</h3>
          <p>
            {{ $t('SNA.s7') }}
          </p> 
            <p>{{ $t('SNA.s8') }}</p>
        </div>
        <div class="image-content">
          <img src="@/assets/images/vetting.jpg" alt="Customs Clearance" class="image-item animate-on-scroll" />
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: 'LogisticsPage',
  mounted() {
    // Create a new IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate__animated', 'animate__fadeInRight'); // Add animation classes when element enters the viewport
          entry.target.style.animationDuration = '1.2s'; // Modify this value to control animation speed
          observer.unobserve(entry.target); // Unobserve the element after the animation has been triggered
        }
      });
    });

    // Target all elements with the class 'animate-on-scroll'
    const elements = document.querySelectorAll('.animate-on-scroll');
    elements.forEach((el) => {
      observer.observe(el); // Start observing each element
    });
  },
};
</script>

<style scoped>
/* Upper Section Styles */
.overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6) 75%, rgba(0, 0, 0, 0) 100%); /* 渐变从左侧全黑到中间半透明，再到右侧透明 */
   z-index: 1;
}
.hero-section {
  position: relative;
  display: flex;
  justify-content: flex-start; /* 将文字靠左对齐 */
  align-items: center;
  padding-top: 80px;
  color: white;
  text-align: left; /* 确保文字在左侧对齐 */
  padding-left: 50px; /* 给文字加上合适的左侧内边距 */
  /* 使用 contain 确保背景图片全部显示 */
  background-image: url('@/assets/images/SNBG.jpeg'); /* 使用 @ 符号引用背景图片 */
  background-size: cover; /* 图片按比例缩放，确保完全显示 */
  background-position: center; /* 图片居中 */
  background-repeat: no-repeat; /* 不重复 */
  height: 100vh; /* 确保背景图的容器高度适合全屏显示 */
}

.text-content {
  max-width: 30%;
  z-index: 2;
}

.text-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.text-content h2 {
  padding-top: 50px;
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-align: left;
}

/* Lower Section Styles */
/* First Container: Image Left, Text Right */
/* First Container: Image Left, Text Right */
.new-section1, .new-section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px;
}

/* 调整图片和文字各占一半空间 */
.new-section1 .image-content, 
.new-section2 .image-content{
  flex-basis: 50%; /* 每个部分各占 50% */
  max-width: 50%; /* 确保不会超出一半的宽度 */
  padding: 10px; /* 给每个部分加上适当的内边距 */
}

.new-section1 .text-content,
.new-section2 .text-content {
  max-width: 35%; /* 确保不会超出一半的宽度 */
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直布局，确保文字从上到下排列 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  text-align: center; /* 文字居中对齐 */
  height: 100%; /* 让容器高度自适应，保证内容居中 */
  margin-right: 100px;
}

.new-section2 {
  flex-direction: row-reverse; /* 反向布局，图片在右，文字在左 */
}

/* 图片样式 */
.new-section1 .image-content img, 
.new-section2 .image-content img {
  width: 100%; /* 确保图片占据其容器的全部宽度 */
  height: auto; /* 高度自动调整 */
  border-radius: 10px;
}


/* 文字样式 */
.new-section1 .text-content h3 {
  font-size: 1.6rem;
  margin-bottom: 20px;
}
.new-section2 .text-content h3 {
  font-size: 1.8rem;
  margin-bottom: 5px;
}

.new-section1 .text-content p, 
.new-section2 .text-content p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.3;
  text-align: left;
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .new-section1 .text-content,
  .new-section2 .text-content {
    margin-right: 50px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    justify-content: center; /* 居中对齐 */
  }
  .hero-section .text-content{
    max-width: 90%;
    max-height: 90%;
    padding-right: 40px;
  }

  .hero-section .text-content h1{
    text-align: left;
    font-size: 34px;
    text-align: center;
  }
  .hero-section .text-content h2{
    text-align: left;
    font-size: 16px;
    text-align: center;
  }
  .new-section1, .new-section2 {
    flex-direction: column;
    text-align: center;
  }

  .new-section1 .text-content, 
  .new-section2 .text-content {
    margin: 0;
    padding: 20px 0; /* 增加一些内边距 */
    text-align: center;
    max-width: 100%;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
  }

  .new-section1 .image-content, 
  .new-section2 .image-content {
    max-width: 100%;
  }

  .new-section1 .image-content img,
  .new-section2 .image-content img {
    width: 100%;
  }

  .text-content h1, .text-content h2, .new-section1 .text-content h3, .new-section2 .text-content h3, .new-section1 .text-content p, .new-section2 .text-content p {
    text-align: center; /* 确保在小屏幕下文字居中 */
  }
}

@media (max-width: 480px) {
  .hero-section {
    justify-content: center; /* 居中对齐 */
  }
  .hero-section .text-content{
    max-width: 90%;
    max-height: 90%;
  }

  .hero-section .text-content h1{
    text-align: left;
    font-size: 34px;
  }
  .hero-section .text-content h2{
    text-align: left;
    font-size: 16px;
  }

  .text-content h1 {
    font-size: 2rem;
    text-align: center; /* 确保 h1 在小屏幕时居中 */
  }

  .text-content h2 {
    font-size: 1rem;
  }

  .new-section1 .text-content p,
  .new-section2 .text-content p {
    font-size: 1rem;
    text-align: center; /* 在小屏幕时文字居中 */
  }

}
</style>
