<template>
  <section id = "home">
    <LandingSection />
</section>

  <!-- About Us -->
  <section id="about">
    <AboutSection />
  </section>

  <!-- Our services -->
  <section id="services">
    <ServiceSection />
  </section>

  <section id="contact">
 <ContactSection />
</section>
</template>

<script>
import LandingSection from '@/pages/Landing/LandingSection.vue';
import ServiceSection from '@/pages/Services/ServiceSection.vue';
import AboutSection from '@/pages/About/AboutSection.vue';
import ContactSection from '@/pages/Contact/ContactSection.vue';



export default {
  name: 'HeroSection',
  components: {
    LandingSection,
    ServiceSection,
    AboutSection,
    ContactSection
  },

  methods: {
    scrollToNextSection() {
      const nextSection = document.querySelector('.aboutUs-section'); // 使用正确的选择器
      if (nextSection) {
        nextSection.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动到下一个部分
      }
    },
  }
}
</script>

<style scoped>


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}

.hero-section, .aboutUs, .map-projects, .contact-page {
  padding-top: 80px; /* 确保内容不会被导航栏覆盖，具体数值根据导航栏高度调整 */
  margin: 0; /* 清除默认 margin */
  box-sizing: border-box; /* 使 padding 包含在宽高内 */
}


</style>
