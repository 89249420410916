<template>
  <div>
    <!-- Existing Section -->
    <section id="uppersec">
      <div class="hero-section">
        <!-- Right Text Section (Keep this to display the text) -->
        <div class="text-content animate-on-scroll">
          <h1>{{ $t('LS.s1') }}</h1>
          <h2>{{ $t('LS.s2') }}</h2>
        </div>
        <div class="overlay"></div>
      </div>
    </section>

    <!-- New Section -->
    <section id="lowersec">
      <!-- Container 1: Image Left, Text Right -->
      <div class="new-section1 animate-on-scroll">
        <div class="image-content">
          <img src="@/assets/images/CLM1.jpg" alt="DDP Service" class="image-item animate-on-scroll" />
        </div>
        <div class="text-content">
          <h3>{{ $t('LS.s3') }}</h3>
          <p>{{ $t('LS.s4') }}</p>
        </div>
      </div>

      <!-- Container 2: Image Right, Text Left -->
      <div class="new-section2 animate-on-scroll">
        <div class="text-content">
          <h3>{{ $t('LS.s5') }}</h3>
          <p>{{ $t('LS.s6') }}</p>
        </div>
        <div class="image-content">
          <img src="@/assets/images/CLM2.jpg" alt="Customs Clearance" class="image-item animate-on-scroll" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LogisticsPage',
  mounted() {
    // Create a new IntersectionObserver
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate__animated', 'animate__fadeInRight'); // Add animation classes when element enters the viewport
          entry.target.style.animationDuration = '1.2s'; // Modify this value to control animation speed
          observer.unobserve(entry.target); // Unobserve the element after the animation has been triggered
        }
      });
    });

    // Target all elements with the class 'animate-on-scroll'
    const elements = document.querySelectorAll('.animate-on-scroll');
    elements.forEach((el) => {
      observer.observe(el); // Start observing each element
    });
  },
};
</script>

<style scoped>
/* Upper Section Styles */
.overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6) 75%, rgba(0, 0, 0, 0) 100%);
   z-index: 1;
}

.hero-section {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 80px;
  color: white;
  text-align: left;
  padding-left: 50px;
  background-image: url('@/assets/images/SupplyChain.png');
  background-size: cover; /* 确保背景图像根据容器缩放 */
  background-position: center; /* 始终保持背景图居中 */
  background-repeat: no-repeat;
  width: 100vw; /* 确保宽度始终为视口宽度 */
  height: 100vh; /* 高度为视口高度，确保全屏显示 */
}

.text-content {
  max-width: 30%;
  z-index: 2;
}

.text-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.text-content h2 {
  padding-top: 50px;
  font-size: 1.2rem;
  margin-bottom: 30px;
  text-align: left;
}

/* Lower Section Styles */
.new-section1, .new-section2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.new-section1 .image-content, 
.new-section2 .image-content {
  flex-basis: 50%;
  max-width: 50%;
  padding: 10px;
}

.new-section1 .text-content,
.new-section2 .text-content {
  max-width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: 100px;
}

.new-section2 {
  flex-direction: row-reverse;
}

.new-section1 .image-content img, 
.new-section2 .image-content img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* 文字样式 */
.new-section1 .text-content h3, 
.new-section2 .text-content h3 {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.new-section1 .text-content p, 
.new-section2 .text-content p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.3;
  text-align: left;
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .new-section1 .text-content,
  .new-section2 .text-content {
    margin-right: 50px;
  }
}

@media (max-width: 768px) {
  .hero-section {
    justify-content: center; /* 居中对齐 */
  }
  .hero-section .text-content{
    max-width: 90%;
    max-height: 90%;
    padding-right: 40px;
  }

  .hero-section .text-content h1{
    text-align: left;
    font-size: 34px;
    text-align: center;
  }
  .hero-section .text-content h2{
    text-align: left;
    font-size: 16px;
    text-align: center;
  }
  .new-section1, .new-section2 {
    flex-direction: column;
    text-align: center;
  }

  .new-section1 .text-content, 
  .new-section2 .text-content {
    margin: 0;
    padding: 20px 0; /* 增加一些内边距 */
    text-align: center;
    max-width: 100%;
    justify-content: center; /* 垂直居中 */
    align-items: center; /* 水平居中 */
  }

  .new-section1 .image-content, 
  .new-section2 .image-content {
    max-width: 100%;
  }

  .new-section1 .image-content img,
  .new-section2 .image-content img {
    width: 100%;
  }

  .text-content h1, .text-content h2, .new-section1 .text-content h3, .new-section2 .text-content h3, .new-section1 .text-content p, .new-section2 .text-content p {
    text-align: center; /* 确保在小屏幕下文字居中 */
  }
}

@media (max-width: 480px) {
  .hero-section {
    justify-content: center; /* 居中对齐 */
  }
  .hero-section .text-content{
    max-width: 90%;
    max-height: 90%;
  }

  .hero-section .text-content h1{
    text-align: left;
    font-size: 34px;
  }
  .hero-section .text-content h2{
    text-align: left;
    font-size: 16px;
  }

  .text-content h1 {
    font-size: 2rem;
    text-align: center; /* 确保 h1 在小屏幕时居中 */
  }

  .text-content h2 {
    font-size: 1rem;
  }

  .new-section1 .text-content p,
  .new-section2 .text-content p {
    font-size: 1rem;
    text-align: center; /* 在小屏幕时文字居中 */
  }
  
}
</style>
