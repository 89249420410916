<template>
  <section ref="aboutSection" class="section1 about-section" :class="{ 'in-view': upperInView }">
    <!-- 上部分标题 -->
    <div class="section-title">
      <span class="line"></span>
      <h1>{{ $t('aboutSection.s1') }}</h1>
      <span class="line"></span>
      <img src="@/assets/images/CompLogo.png" alt="Logo" class="background-logo">
    </div>
  </section>

  <div ref="section1" :class="['section1', { 'in-view': upperInView }]">
    <div class="container1">
      <div class="section1-content">
        <div class="section1-text">
          <h2>{{ $t('aboutSection.s2') }}</h2>
          <h3 class="keywords-text">
            {{ $t('aboutSection.k1') }}<br>
          </h3>
          <p>{{ $t('aboutSection.s3') }}</p>
        </div>
        <div class="section1-image">
          <img src="@/assets/images/para1.jpg" class="image1">
        </div>
      </div>
    </div>
  </div>

  <div ref="section2" :class="['section2', { 'in-view': lowerInView }]">
    <div class="container2">
      <div class="section2-content">
        <div class="section2-image">
          <img src="@/assets/images/solution.jpg" class="image2">
        </div>
        <div class="section2-text">
          <h2>{{ $t('aboutSection.s4') }}</h2>
          <h3 class="keywords-text">
            {{ $t('aboutSection.k2') }}<br>
          </h3>
          <p>{{ $t('aboutSection.s5') }}</p>
        </div>
      </div>
    </div>
  </div>

  <div ref="section3" :class="['section3', { 'in-view': section3InView }]">
    <div class="container3">
      <div class="section3-content">
        <div class="section3-text">
          <h2>{{ $t('aboutSection.s6') }}</h2>
          <h3 class="keywords-text">
            {{ $t('aboutSection.k3') }}<br>
          </h3>
          <p>{{ $t('aboutSection.s7') }}</p>
        </div>
        <div class="section3-image">
          <img src="@/assets/images/para5.jpg" class="image3">
        </div>
      </div>
    </div>
  </div>

  <div ref="section4" :class="['section4', { 'in-view': section4InView }]">
  <div class="container4">
    <div class="section4-content">
      <div class="section4-image">
        <img src="@/assets/images/founder.jpeg" class="image4">
      </div>
      <div class="section4-text">
        <h2>{{ $t('profile.s1') }}</h2>
        <p class="author-text">
            {{ $t('profile.s3') }}<br>
            {{ $t('profile.s4') }}        
        </p>
        <p class="highlighted-text">
          "{{ $t('profile.s2') }}"
        </p>        
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  name: 'AboutSection',
  data(){
    return {
      upperInView: false,
      lowerInView: false,
      section3InView: false,
      section4InView: false,

    }
  },
  mounted() {
    this.createObserver();
  },
  methods: {
    createObserver() {
      const options = {
        root: null,
        threshold: 0.4,
      };

      const section1Target = this.$refs.section1;
      if (section1Target) {
        const section1Observer = new IntersectionObserver(this.handleIntersectSection1, options);
        section1Observer.observe(section1Target);
      }

      const section2Target = this.$refs.section2;
      if (section2Target) {
        const section2Observer = new IntersectionObserver(this.handleIntersectSection2, options);
        section2Observer.observe(section2Target);
      }

      const section3Target = this.$refs.section3;
      if (section3Target) {
        const section3Observer = new IntersectionObserver(this.handleIntersectSection3, options);
        section3Observer.observe(section3Target);
      }

      const section4Target = this.$refs.section4;
      if (section4Target) {
        const section4Observer = new IntersectionObserver(this.handleIntersectSection4, options);
        section4Observer.observe(section4Target);
      }
    },
    handleIntersectSection1(entries, observer) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.upperInView = true;
        observer.unobserve(entry.target);
      }
    },
    handleIntersectSection2(entries, observer) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.lowerInView = true;
        observer.unobserve(entry.target);
      }
    },
    handleIntersectSection3(entries, observer) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.section3InView = true;
        observer.unobserve(entry.target);
      }
    },
    handleIntersectSection4(entries, observer) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.section4InView = true;
        observer.unobserve(entry.target);
      }
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container4 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.section4-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%; /* Ensure the content takes the full width */

}

.section4-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image4 {
  width: 100%;
  max-width: 400px; /* Adjust the max width for image size */
  border-radius: 50%; /* Make the image circular */
}

.section4-text {
  flex: 1;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the text */
  align-items: flex-start; /* Keep the text aligned to the left */
  height: 100%; /* Ensures the container takes full height */
  text-align: left;
}


.highlighted-text {
  font-size: 1.2rem;
  font-style: italic;
  color: #000000;
  margin-bottom: 15px;
  text-align: left;
  max-width: 650px;

}

.author-text {
  max-width: 400px;
  font-weight: bold;
  font-size: 1rem;
  color: #E94E77; /* Match the color of the author's name and details */
  text-align: left;
  margin-bottom: 20px;
}


.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}

.background-logo {
  width: 80px;
  opacity: 0.1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.section-title h1 {
  font-size: 3rem;
  font-weight: bold;
  color: #333;
  position: relative;
  z-index: 1;
}

.section-title .line {
  flex-grow: 1;
  height: 2px;
  background-color: #c5d4e2;
  margin: 0 15px;
  z-index: 1;
}

.container1, .container2, .container3 {
  width: 100%;
  margin: 0 auto;
  max-width: 1200px; /* 最大宽度 */
  padding: 0 20px; /* 两侧留白 */
}

.section1, .section2, .section3, .section4 {
  opacity: 0;
  transform: translateX(-50px);
  transition: all 1s ease-in-out;
}

.in-view {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
}

.section1-content, .section3-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 0;
}


.section2-content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
}

.section2-text{
  max-width: 400px;
  line-height: 1.6;
  text-align: left;
}

.section1-text, .section3-text {
  max-width: 400px;
  font-size: 18px;
  line-height: 1.6;
  text-align: left;
}

.section1-text h2, .section2-text h2, .section3-text h2, .section4-text h2{
  font-size: 40px;
  line-height: normal;
}
.section1-text h3, .section2-text h3, .section3-text h3, .section4-text h3{
  font-size: 22px;
  font-style: italic;
  margin-bottom: 30px;
  color: #E94E77; /* Match the color of the author's name and details */
}
.section1-text p, .section2-text p, .section3-text p, .section4-text p{
  font-size: 26px;
}

s

.section1-image, .section2-image, .section3-image {
  width: 600px; /* 固定宽度 */
  height: 400px; /* 固定高度 */  
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image1, .image2, .image3 {
  width: 100%;
  height: auto;
  max-width: 700px;
  border-radius: 12px;
}

/* 响应式设计 */
@media (max-width: 1024px) {
  .section1-content, .section2-content, .section3-content {
    flex-direction: column;
    text-align: center;
  }

  .section1-text, .section2-text, .section3-text {
    padding-bottom: 20px;
    max-width: 100%;
    padding: 30px;

  }

  .section1-image, .section2-image, .section3-image {
    width: 100%;
  }

  .image1, .image2, .image3 {
    width: 90%;
  }
}

@media (max-width: 768px) {
    .section1-content, .section2-content, .section3-content {
    flex-direction: column;
    text-align: center;
  }
  .section-title h1 {
    font-size: 1.5rem;
  }
  .section1-text{
    margin-bottom: 30px;
  }

  .section1-text, .section2-text, .section3-text {
    font-size: 16px;
    padding: 30px;
  }
  .section4-content {
    flex-direction: column;
  }

  .section4-image, .section4-text {
    max-width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }

  .image4 {
    max-width: 150px; /* Smaller image for mobile */
  }
}

@media (max-width: 480px) {
  .section-title h1 {
    font-size: 1.2rem;
  }

  .section1-text, .section2-text, .section3-text {
    font-size: 14px;
    padding: 30px;
  }

  .image1, .image2, .image3 {
    width: 100%;
  }
}
</style>
