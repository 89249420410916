// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import IndexPage from '../pages/Index/IndexPage.vue';
import PrivacyStatement from '@/pages/Others/PrivacyStatement.vue';
import LogisticsSolution from '@/pages/Others/LS.vue';
import MultilingualCustomerSupport from '@/pages/Others/MCS.vue';
import CostEffictiveSolution from '@/pages/Others/CES.vue';
import SupplierNetworkAccess from '@/pages/Others/SNA.vue';
import NegotiationsManagement from '@/pages/Others/ISNRM.vue';



const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexPage,
  },

  {
    path: '/privacy-statement',
    name: 'PrivacyStatement',
    component: PrivacyStatement,
  },
  
  {
    path: '/logistics-solution',
    name: 'LogisticsSolution',
    component: LogisticsSolution,
  },

  {
    path: '/multilingual-customer-support',
    name: 'MultilingualCustomerSupport',
    component: MultilingualCustomerSupport,
  },

  {
    path: '/cost-effective-solution',
    name: 'CostEffictiveSolution',
    component: CostEffictiveSolution,
  },

  {
    path: '/supplier-network-access',
    name: 'SupplierNetworkAccess',
    component: SupplierNetworkAccess,
  },

  {
    path: '/negotiations-management',
    name: 'NegotiationsManagement',
    component: NegotiationsManagement,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        top: 150, // 调整此值，根据导航栏的高度进行设置
      };
    } else {
      return { top: 0 };
    }
  }
});


export default router;
