<template>
  <section ref="contactSection" class="contact-section" :class="{ 'in-view': inView }">
    <div class="center-section">
      <div :class="['content', { 'animate__animated animate__fadeInLeft': inView }]">
        <h1>{{ $t('contactusSection.s1') }}</h1>
        <p>{{ $t('contactusSection.s2') }}</p>
        <button class="book-now-btn" @click="toggleForm">{{ $t('contactusSection.s3') }}</button>
      </div>
    </div>
    <div class="overlay"></div>

<!-- 表单弹出框 -->
<transition
  @before-enter="beforeEnter"
  @enter="enter"
  @leave="leave"
>
    <div v-if="showForm" class="form-popup animate__animated animate__fadeInRight">
      <ContactForm @close="showForm = false" />
    </div>
</transition>
  </section>
</template>


<script>
import ContactForm from '@/pages/Contact/ContactForm.vue';

export default {
  name: 'ContactSection',
  components: {
    ContactForm
  },
  data() {
    return {
      showForm: false,
      inView: false,
      backgroundPositionY: '100%',
    };
  },
  mounted() {
    this.createObserver();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    createObserver() {
      const options = {
        root: null,
        threshold: 0.4,
      };

      const observer = new IntersectionObserver(this.handleIntersect, options);
      const target = this.$refs.contactSection;
      if (target) {
        observer.observe(target);
      }
    },
    handleIntersect(entries, observer) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.inView = true;
        observer.unobserve(entry.target);
      }
    },
    handleScroll() {
      const section = this.$refs.contactSection;
      if (section) {
        const sectionTop = section.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (sectionTop < windowHeight && sectionTop > 0) {
          const relativeScroll = (windowHeight - sectionTop) / windowHeight;
          this.backgroundPositionY = `${100 - relativeScroll * 100}%`;
        }
      }
    },
    beforeEnter(el) {
      el.classList.remove('animate__zoomOutRight');
      el.classList.add('animate__zoomInRight');
    },
    enter(el, done) {
      el.addEventListener('animationend', done);
    },
    leave(el, done) {
      el.classList.remove('animate__zoomInRight');
      el.classList.add('animate__zoomOutRight');
      el.addEventListener('animationend', done);
    }
  }
};
</script>

<style scoped>
/* 主容器样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contact-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh; /* 高度占满视口 */
  background-image: url('@/assets/images/contactBG.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  transition: background-position 0.3s ease;
  background-position-y: v-bind(backgroundPositionY);
}

.center-section {
  z-index: 2;
  text-align: center; /* 文字居中对齐 */
  max-width: 600px; /* 限制文字最大宽度 */
}

.content h1 {
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 20px;
}

.content p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 30px;
  line-height: 1.6;
}

.book-now-btn {
  padding: 12px 24px;
  background-color: #000000;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.book-now-btn:hover {
  background-color: green;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

/* 表单弹出框样式 */
.form-popup {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 5;
  padding: 20px;
  border-radius: 10px;
}

</style>