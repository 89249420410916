<template>
  <section class="hero">
    <!-- 视频背景 -->
    <video autoplay muted loop id="background-video" poster="/assets/images/poster.png">
      <source src="@/assets/video/landing.webm" type="video/webm" />
      Your browser does not support the video tag.
    </video>
    <div class="overlay"></div>
    <div id="background-image" class="poster-image"></div>


    
    <div class="hero-content">
      <div class="left-text">
        <h1>{{ $t('landingSection.s1') }}</h1>
        <h1>{{ $t('landingSection.s2') }}</h1>
      </div>
      <div class="right-text">
        <p>{{ $t('landingSection.s3') }}</p>
        <p>{{ $t('landingSection.s4') }}</p>
        <router-link :to="{ path: '/', hash: '#about' }" class="btn">{{ $t('landingSection.s5') }}</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingSection",
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1; /* 确保视频在文字后面 */
}

/* 背景图片默认隐藏 */
.poster-image {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-image: url('@/assets/images/poster.png');
  background-size: cover;
  background-position: center;
  transform: translate(-50%, -50%);
  z-index: -1; /* 确保背景图片在文字后面 */
}

.overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.7); /* 调整遮罩层透明度 */
   z-index: 1;
}

.hero {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden; /* 防止超出页面的部分 */

}

.hero-content {
  display: flex;
  justify-content: space-between;
  width: 80%;
  z-index: 2;
}

.left-text {
  flex: 1;
  text-align: left;
  font-size: 50px;
  width: 300px;
}


.right-text {
  flex: 1;
  margin-top: 5%;
  font-size: 24px;
  justify-content: center;
  text-align: left;
}


.btn {
    position: absolute; /* 使按钮相对于其父容器绝对定位 */
    bottom: 20px; /* 距离父容器底部 20px */
    right: 20px; /* 距离父容器右侧 20px */
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    border: 2px solid white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
  }

.btn:hover {
  background-color: white;
  color: black;
}

/* Responsive Design */
@media (max-width: 399px) {

  #background-video {
    display: none; /* 隐藏视频 */
  }

  .poster-image {
    display: block; /* 显示背景图片 */
  }
  .hero {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  color: white;
  overflow: hidden; /* 防止超出页面的部分 */
}

.hero-content {
  display: flex;
  flex-direction: column; /* 改为纵向排列，使元素从上到下排列 */
  align-items: center; /* 水平方向居中对齐 */
  justify-content: center; /* 垂直方向居中 */
  width: 80%;
  max-width: 600px; /* 限制内容的最大宽度，以保持良好布局 */
  z-index: 2;
  text-align: center; /* 居中文字内容 */
  gap: 20px; /* 为各个子元素之间增加间距 */
}

.left-text h1{
  margin: 10px 0; /* 给标题增加适当的上下间距 */
  font-size: 30px;
}

.right-text {
  margin-top: 10px; /* 给描述文字和按钮之间增加一些间距 */
}

.right-text p {
  font-size: 18px; /* 确保描述文字字体大小一致 */
  margin: 10px 0; /* 给每段描述文字增加间距 */
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  border: 2px solid white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.btn:hover {
  background-color: white;
  color: black;
}


}



@media (min-width: 0px) and (max-width: 768px) {
  #background-video {
    display: none; /* 隐藏视频 */
  }

  .poster-image {
    display: block; /* 显示背景图片 */
  }
  .hero {
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  color: white;
  overflow: hidden; /* 防止超出页面的部分 */
}

.hero-content {
  display: flex;
  flex-direction: column; /* 改为纵向排列，使元素从上到下排列 */
  align-items: center; /* 水平方向居中对齐 */
  justify-content: center; /* 垂直方向居中 */
  width: 80%;
  max-width: 600px; /* 限制内容的最大宽度，以保持良好布局 */
  z-index: 2;
  text-align: center; /* 居中文字内容 */
  gap: 20px; /* 为各个子元素之间增加间距 */
}

.left-text h1{
  margin: 10px 0; /* 给标题增加适当的上下间距 */
  font-size: 30px;
}

.right-text {
  margin-top: 10px; /* 给描述文字和按钮之间增加一些间距 */
}

.right-text p {
  font-size: 18px; /* 确保描述文字字体大小一致 */
  margin: 10px 0; /* 给每段描述文字增加间距 */
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  border: 2px solid white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.btn:hover {
  background-color: white;
  color: black;
}


}

@media (min-width: 768px) and (max-width: 1280px) {
  .hero-content {
    display: flex;
    flex-direction: row; /* 横向排列 */
    align-items: center; /* 垂直方向上居中 */
    justify-content: space-between; /* 左右两部分分布均匀 */
    width: 100%;
    max-width: 1200px; /* 限制最大宽度，避免内容过宽 */
    margin: 0 auto; /* 居中容器 */
    gap: 20px; /* 两部分之间的间距 */
    text-align: left; /* 确保文本对齐方式为左对齐 */
  }

  .left-text,
  .right-text {
    flex: 1; /* 每部分占据 50% 的宽度 */
    padding: 20px; /* 给每部分增加一些内边距 */
    text-align: left; /* 强制文字在每个部分内左对齐 */
  }

  .left-text h1{
    font-size: 50px; /* 调整字体大小以适应平板屏幕 */
  }
  .right-text p{
    font-size: 24px;
    position: relative;
  }

  .btn {
    position: absolute; /* 使按钮相对于其父容器绝对定位 */
    bottom: 20px; /* 距离父容器底部 20px */
    right: 20px; /* 距离父容器右侧 20px */
    padding: 10px 20px;
    color: white;
    text-decoration: none;
    border: 2px solid white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
  }
}

</style>
