  <template>
      <div class="popup-form">
        <div class="form-header">
      <h2>Contact Us</h2>
      <button class="close-btn" @click="closeForm">
        <i class="icon-cancel-1"></i>
      </button>
    </div>
        <form @submit.prevent="submitTicket" novalidate>
          <div class="form-group">
            <label for="fullName">Full name*</label>
            <input type="text" 
            v-model="form.fullName" 
            @input="clearFullNameError"  
            @blur="validateFullName"     
            required 
            />
            <span v-if="errors.fullName">{{ errors.fullName }}</span>          </div>
          <div class="form-group">
            <label for="email">E-Mail*</label>
            <input type="email" 
            v-model="form.email" 
            @input="clearEmailError" 
            @blur="validateEmail" 
            required 
            />
            <span v-if="errors.email">{{ errors.email }}</span>          </div>
          <div class="form-group">
            <label for="phone">Phone*</label>
            <input type="text" 
            v-model="form.phone" 
            @input="clearPhoneError" 
            @blur="validatePhone"
            required
            />
            <span v-if="errors.phone">{{ errors.phone }}</span>          </div>
          <div class="form-group">
            <label for="country">Country/Region</label>
            <input type="text" v-model="form.country" />
          </div>
          <div class="form-group">
            <label for="message">Message*</label>
            <textarea v-model="form.message" required></textarea>
          </div>
          <!-- 隐私政策复选框 -->
        <div>
          <input type="checkbox" id="privacyCheck" v-model="form.acceptedPrivacy" />
          <label for="privacyCheck">
            To submit this form, you need to accept our
            <router-link to="/privacy-statement" class="btn">Privacy Statement</router-link>
          </label>
        </div>
        <span v-if="errors.acceptedPrivacy" class="error">{{ errors.acceptedPrivacy }}</span>
          

        <!-- reCAPTCHA -->
        <div id="recaptcha" class="g-recaptcha" :data-sitekey="siteKey"></div>
        <button type="submit">Submit</button>
        </form>
      </div>
  </template>
  
  <script>
  export default {
    name: "ContactForm",
    data() {
      return {
        siteKey:'6LenOE8qAAAAAFXk5X33FOVQVxEo_A0xO98omVEU',
        form: {
          fullName: '',
          email: '',
          country: '',
          phone: '',
          message: '',
          acceptedPrivacy: false, // 添加复选框的状态
          recaptchaResponse: '',
        },
        errors: {
          fullName: '',
          email: '',
          phone: '',
          acceptedPrivacy: '', // 复选框的错误信息
          recaptchaResponse: ''
        },
      };
    },

    mounted() {
      if (!window.grecaptcha) {
        const recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
        recaptchaScript.setAttribute('async', true);
        recaptchaScript.setAttribute('defer', true);
        document.head.appendChild(recaptchaScript);
      }
      else {
      this.initRecaptcha();
    }

    window.onRecaptchaLoadCallback = () => {
      this.initRecaptcha();
    };
    },

    
    methods: {
      closeForm() {
      this.$emit('close'); // 触发父组件关闭表单
    },

      initRecaptcha() {
      this.recaptchaWidgetId = grecaptcha.render('recaptcha', {
        sitekey: this.siteKey,
        callback: this.onRecaptchaSuccess,
        'expired-callback': this.onRecaptchaExpired
      });
    },

    onRecaptchaSuccess(token) {
      this.form.recaptchaResponse = token;
    },

    onRecaptchaExpired() {
      this.form.recaptchaResponse = ''; // 清空过期的响应
    },
      handleSubmit() {
    if (!window.grecaptcha) {
      alert("Google reCAPTCHA 尚未加载，请稍后再试");
      return;
    }

    // 获取 reCAPTCHA 的响应值
    this.form.recaptchaResponse = grecaptcha.getResponse();

    // 检查是否有 reCAPTCHA 客户端
    if (typeof grecaptcha.getResponse !== 'function') {
      alert("reCAPTCHA 客户端尚未初始化，请稍后再试");
      return;
    }

    this.form.recaptchaResponse = grecaptcha.getResponse();

    if (!this.form.recaptchaResponse) {
      this.errors.recaptchaResponse = "请完成 CAPTCHA 验证";
      alert("请完成 CAPTCHA 验证");
      return;
    } else {
      this.errors.recaptchaResponse = '';
    }

    // 处理表单提交逻辑，例如将 recaptchaResponse 发送到后端
      console.log("reCAPTCHA 验证通过，响应值：", this.form.recaptchaResponse);
  },


      clearFullNameError() {
        this.errors.fullName = '';
      },
      clearEmailError() {
        this.errors.email = '';
      },
      clearPhoneError() {
        this.errors.phone = '';
      },
  
  
      validateFullName() {
        if (!this.form.fullName) {
          this.errors.fullName = 'Full Name is required.';
        } else {
          this.errors.fullName = '';
        }
      },
      validateEmail() {
        const emailPattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if (!emailPattern.test(this.form.email)) {
          this.errors.email = 'Please enter a valid email address';
        } else {
          this.errors.email = '';
        }
      },
      validatePhone() {
    const phonePattern = /^\d*$/;
    if (!this.form.phone) {
      this.errors.phone = 'Phone number is required.'; // 确保处理空值的情况
    } else if (!phonePattern.test(this.form.phone)) {
      this.errors.phone = 'Please enter a valid phone number';
    } else {
      this.errors.phone = '';
    }
  },
  
      validatePrivacy() {
        if (!this.form.acceptedPrivacy) {
          this.errors.acceptedPrivacy = 'You must accept the Privacy Statement to submit the form.';
        } else {
          this.errors.acceptedPrivacy = '';
        }
      },
  
      
      submitTicket() {
        this.validateFullName();
        this.validateEmail();
        this.validatePhone();
        this.validatePrivacy();
        this.handleSubmit();
  
        if (this.errors.fullName || this.errors.email || this.errors.phone || this.errors.acceptedPrivacy || this.errors.recaptchaResponse) {
          alert('Please correct the errors before submitting.');
          return;
        }
        
        // 使用 fetch 将表单数据发送到后端
        fetch('https://horizontalsourcing-16207d685b98.herokuapp.com/submit-ticket', {
          method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      recaptchaResponse: this.form.recaptchaResponse,
      fullName: this.form.fullName,
      email: this.form.email,
      country: this.form.country,
      phone: this.form.phone,
      message: this.form.message
    }),
  })
    .then(response => response.json())
    .then(data => {
      alert(data.message); // 显示提交成功的消息
    })
    .catch(error => {
    console.error('Error:', error);
    alert('Failed to submit the form. Please try again later.');
  });
      },
    },
  };
  </script>
  
  <style scoped>
  /* 表单弹出框样式 */
  .popup-form {
    position: relative;
    top: 10%;
    right: 10%;
    background: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    padding: 30px;
    width: 400px;
    z-index: 1000;
    text-align: left;
    overflow-y: auto;
  }
  
  /* 固定关闭按钮在表单右上角 */
  .close-btn {
    position: absolute; /* 绝对定位使其相对父容器 (popup-form) */
    top: 10px; /* 距离表单顶部10px */
    right: 10px; /* 距离表单右侧10px */
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .close-btn:hover {
    color: #ff0000; /* 悬停时变为红色 */
  }
  
  .form-header {
    margin-bottom: 20px;
  }
  
  .form-header h2 {
    font-size: 24px;
  }
  
  /* 表单组的样式 */
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .form-group textarea {
    resize: none;
    height: 100px;
  }
  
  /* 响应式设计 */
/* 响应式设计 */
@media (max-width: 768px) {
  .popup-form {
    width: 80%; /* 调整为 80% 屏幕宽度 */
    top: 5%; /* 距离顶部5% */
    right: 0; /* 保持表单靠右 */
    padding: 20px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
  }

  .close-btn {
    top: 10px;
    right: 10px; /* 保持关闭按钮在表单右上角 */
  }
}

@media (max-width: 480px) {
  .popup-form {
    width: 100%; /* 调整为 80% 屏幕宽度 */
    height: 50%;
    top: 5%; /* 距离顶部5% */
    right: 0; /* 保持表单靠右 */
    padding: 20px;
  }

  .form-group input,
  .form-group textarea {
    padding: 8px;
  }

  .close-btn {
    top: 10px;
    right: 10px; /* 保持关闭按钮在表单右上角 */
  }
}
  
</style>