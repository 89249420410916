<template>
  <div :class="['header', { 'scrolled': scroll > 50 || !isHomePage }]" :style="{ backgroundColor }">
    <!-- Logo Section -->
    <div class="CompLogo" :style="{ opacity: logoOpacity, color: textColor }">
      <li><router-link :to="{ path: '/', hash: '#home' }">
        <div class="logo-wrapper">
          <img :src="logo1Src" alt="Company Logo 1" class="logo-img" :style="{ opacity: logo1Opacity }" />
          <img :src="logo2Src" alt="Company Logo 2" class="logo-img logo-absolute" :style="{ opacity: logo2Opacity }" />
        </div>
      </router-link></li>
    </div>

    <!-- Hamburger Icon for Mobile -->
    <div class="hamburger" @click="toggleNav">
      <span></span>
      <span></span>
      <span></span>
    </div>

    <!-- Navigation Links Section -->
    <nav :class="['header-nav', { 'open': navOpen }]">
      <ul>
        <li><router-link :to="{ path: '/', hash: '#home' }" :style="{ color: textColor }">{{ $t('navigation.home') }}</router-link></li>
        <li><router-link :to="{ path: '/', hash: '#about' }" :style="{ color: textColor }">{{ $t('navigation.about') }}</router-link></li>
        <li><router-link :to="{ path: '/', hash: '#services' }" :style="{ color: textColor }">{{ $t('navigation.services') }}</router-link></li>
        <li><router-link :to="{ path: '/', hash: '#contact' }" :style="{ color: textColor }">{{ $t('navigation.contact') }}</router-link></li>
      </ul>
    </nav>

    <!-- Language Selector Section -->
    <div class="language-selector" :style="{ color: textColor }">
      <div class="current-language" @click="toggleDropdown">
        <span>{{ currentLanguageLabel }}</span>
        <i class="arrow-down" :style="{ 'border-color': textColor }"></i>
      </div>
      <ul :class="{'language-dropdown': true, 'show': dropdownVisible}" class="language-dropdown">
        <li @click="changeLanguage('zh')">中文</li>
        <li @click="changeLanguage('en')">English</li>
        <li @click="changeLanguage('fr')">Français</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useLanguageStore } from '@/stores/languageStore';
import { useRoute } from 'vue-router';
import { i18n, loadLocaleMessages } from '@/i18n';


export default {
  name: 'HeaderComponent',
  setup() {
    const languageStore = useLanguageStore();
    const dropdownVisible = ref(false);
    const scroll = ref(0);
    const navOpen = ref(false); 
    const route = useRoute();

    // 是否为首页
    const isHomePage = computed(() => route.path === '/');

    // 文本颜色
    const textColor = computed(() => {
      if (isHomePage.value && scroll.value < 50) {
        const minScroll = 0;
        const maxScroll = 300;
        const scrollPos = Math.min(Math.max(scroll.value, minScroll), maxScroll);
        const colorValue = 255 - Math.floor((scrollPos / maxScroll) * 255);
        return `rgb(${colorValue}, ${colorValue}, ${colorValue})`; // 首页时动态颜色
      } else {
        return 'rgb(0, 0, 0)'; // 非首页时黑色文字
      }
    });

    // 背景颜色
    const backgroundColor = computed(() => {
      return isHomePage.value && scroll.value < 50 ? 'transparent' : '#ffffff'; // 非首页白色背景
    });

    const logo1Src = require('@/assets/images/CompLogo2.png');
    const logo2Src = require('@/assets/images/CompLogo1.png');

    const logo1Opacity = computed(() => {
  if (isHomePage.value) {
    const maxScroll = 300;
    return 1 - Math.min(scroll.value / maxScroll, 1); // 主页滚动时透明度变化
  } else {
    return 0; // 非主页保持 logo1 显示
  }
});

const logo2Opacity = computed(() => {
  if (isHomePage.value) {
    const maxScroll = 300;
    return Math.min(scroll.value / maxScroll, 1); // 主页滚动时透明度变化
  } else {
    return 1; // 非主页保持 logo2 不显示
  }
});

const currentLanguageLabel = computed(() => {
  switch (languageStore.currentLocale) {
    case 'en':
      return 'English';
    case 'zh':
      return '中文';
    case 'fr':
      return 'Français';
    default:
      return 'English';  // 默认值为英文
  }
});




    const toggleDropdown = () => {
      dropdownVisible.value = !dropdownVisible.value;
    };

    const changeLanguage = (lang) => {
  if (languageStore.currentLocale !== lang) {
    languageStore.currentLocale = lang;  // 直接设置选择的语言
    i18n.global.locale.value = lang;     // 同步设置 i18n 的当前语言
    loadLocaleMessages(lang);            // 动态加载对应的语言文件
  }
  dropdownVisible.value = false; // 关闭语言下拉菜单
};



    const updateScroll = () => {
      scroll.value = window.scrollY;
    };

    const toggleNav = () => {
      navOpen.value = !navOpen.value;
    };

    onMounted(() => {
      window.addEventListener('scroll', updateScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', updateScroll);
    });

    return {
      currentLanguageLabel,
      toggleDropdown,
      changeLanguage,
      dropdownVisible,
      scroll,
      navOpen,
      toggleNav,
      textColor,
      backgroundColor,
      logo1Src,
      logo2Src,
      logo1Opacity,
      logo2Opacity,
      isHomePage,
    };
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between; /* 左右布局 */
  align-items: center;
  position: fixed;
  height: 80px;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0px 20px;
  background-color: transparent;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 5;
  list-style: none; /* 移除圆点 */
}

.header.scrolled {
  background-color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Logo Section */
.CompLogo{
  flex: 1;
}
/* 确保两张图片叠加显示在同一个位置 */
.logo-wrapper {
  position: relative;
  width: 200px; /* 确保宽度固定 */
  height: 100px; /* 确保高度合适 */
}

.logo-img {
  opacity: 1; /* 初始状态完全显示 */
  position: absolute; /* 确保图片叠加 */
  top: 0;
  left: 0;
  width: auto;
  height: 50%;
  transition: opacity 0.3s ease; /* 为透明度设置过渡效果 */
  padding-top: 30px;
}

.logo-absolute {
  z-index: 1; /* 叠加在上面的一层 */
  position: absolute;
  width: auto;
  height: 50%;
  padding-top: 30px;
}

.CompLogo a:hover {
  text-decoration: none;
  opacity: 0.8; /* 悬停时调整透明度 */
}

/* Navigation Section */
.header-nav {
  flex: 3; /* 导航栏位于中间，宽度占用两倍 */
  text-align: center;
}

.header-nav ul {
  display: flex;
  justify-content: center; /* 居中排列 */
  list-style: none;
  padding: 0;
  margin: 0;
}

.header-nav li {
  margin: 40px;
}

.header-nav a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: color 0.3s, transform 0.3s;
}

.header-nav a:hover {
  color: #ff0000;
  transform: scale(1.1);
}

/* Language Selector Section */
.language-selector {
  flex: 1; /* 语言选择器靠右 */
  display: flex;
  position: relative;
  color: #ffffff;
  
}

.current-language {
  display: flex;
  align-items: center;
  cursor: pointer;
  
}

.arrow-down {
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 5px;
  transform: rotate(45deg);
  transition: transform 0.3s, border-color 0.3s ease; /* 添加边框颜色的平滑过渡 */
}

/* Language Dropdown */
.language-dropdown {
  position: absolute;
  top: 100%;
  background-color: #fff;
  color: #000000; /* 黑色文字 */
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(43, 38, 38, 0.1);
  list-style: none;
  padding: 0;
  width: 100px;
  z-index: 100;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.language-dropdown.show {
  max-height: 200px;
  opacity: 1;
}

.language-dropdown li {
  padding: 8px 12px;
  cursor: pointer;
  text-align: center;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px;
}

.hamburger span {
  height: 3px;
  width: 25px;
  background: #333;
  margin: 4px;
}

.CompLogo a {
  display: inline-block; /* 确保旋转时不会影响其他布局 */
  font-size: xx-large;
  text-decoration: none; /* 移除下划线 */
  color: #333; /* 链接文字颜色 */
  font-weight: bold;
}
.CompLogo a:hover {
  text-decoration: none; /* 确保悬停时没有下划线 */
  color: #ff0000;
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    padding: 10px 0;
  }

  .logo-img {
  opacity: 1; /* 初始状态完全显示 */
  position: absolute; /* 确保图片叠加 */
  width: 80%;
  height: auto;
  transition: opacity 0.3s ease; /* 为透明度设置过渡效果 */
  padding-top: 30px;
  padding-left: 20px;
}

.logo-absolute {
  z-index: 1; /* 叠加在上面的一层 */
  position: absolute;
  width: 80%;
  height: auto;
  padding-top: 30px;
  padding-left: 20px;
}

  .header-nav {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 60px;
    left: 0;
    z-index: 100;
  }

  .header-nav.open {
    display: flex;
  }

  .header-nav ul {
    flex-direction: column;
  }

  .hamburger {
    display: flex;
  }

  .CompLogo img {
    width: 100px;
  }
}
</style>
