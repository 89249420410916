<template>
    <div class="privacy-statement-page">
      <div class="container">
        <h1>Privacy Statement</h1>
        <p>Last updated: September 17, 2024</p>
  
        <section class="section">
          <h2>Introduction</h2>
          <p>
            This Privacy Statement explains how Horizontal Sourcing collects, uses, and protects your personal information. We are committed to protecting your privacy and ensuring the security of your personal information. Please read this statement carefully to understand our practices regarding your personal data.
          </p>
        </section>
  
        <section class="section">
          <h2>Information We Collect</h2>
          <p>We may collect the following types of information from you:</p>
          <ul>
            <li>Personal Identification Information (e.g., name, email address, phone number)</li>
            <li>Demographic Information (e.g., location, preferences)</li>
            <li>Technical Data (e.g., IP address, browser type, operating system)</li>
          </ul>
        </section>
  
        <section class="section">
          <h2>How We Use Your Information</h2>
          <p>We use the information we collect for the following purposes:</p>
          <ul>
            <li>To provide, operate, and maintain our website and services</li>
            <li>To improve and personalize your experience on our website</li>
            <li>To communicate with you, including customer service, support, and updates</li>
            <li>To analyze website traffic and user behavior for improving our services</li>
            <li>To comply with legal obligations and prevent fraud or abuse</li>
          </ul>
        </section>
  
        <section class="section">
          <h2>Sharing Your Information</h2>
          <p>
            We may share your information with third-party service providers to help us operate our business, such as payment processors, email service providers, and analytics tools. We ensure these third parties have appropriate data protection measures in place.
          </p>
        </section>
  
        <section class="section">
          <h2>Your Rights</h2>
          <p>
            You have certain rights regarding your personal data, including the right to access, correct, delete, or restrict the use of your data. You may also have the right to object to processing and request data portability.
          </p>
          <p>
            To exercise these rights, please contact us at <a href="mailto:support@horizontalsourcing.com">support@horizontalsourcing.com</a>.
          </p>
        </section>
  
        <section class="section">
          <h2>Data Security</h2>
          <p>
            We take data security seriously and have implemented measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the Internet or electronic storage is 100% secure.
          </p>
        </section>
  
        <section class="section">
          <h2>Changes to This Privacy Statement</h2>
          <p>
            We may update this Privacy Statement from time to time. Any changes will be posted on this page with an updated date. We encourage you to review this page periodically to stay informed about how we protect your information.
          </p>
        </section>
  
        <section class="section">
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Statement or our data practices, please contact us at:
          </p>
          <p>Email: <a href="mailto:support@horizontalsourcing.com">support@horizontalsourcing.com</a></p>
          <p>Phone: +778-929-8986</p>
        </section>
      </div>
    </div>
  </template>
  
  <style scoped>
  .privacy-statement-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    background-color: #f7f7f7;
    text-align: center;
    align-items: center;
    padding: 200px 40px 200px; /* 增加顶部内边距，避免与 header 重叠 */
  }

  .privacy-statement-page::before {
  content: '';
  position: fixed; /* 背景图固定在屏幕上，而不是容器内 */
  top: 50%; /* 水平居中 */
  left: 50%; /* 垂直居中 */
  transform: translate(-50%, -50%); /* 通过 transform 将背景图真正居中 */
  width: 60%; /* 控制背景图的宽度 */
  height: 60%; /* 控制背景图的高度 */
  background: url('@/assets/images/CompLogo.png') center/contain no-repeat; /* 替换为你的背景图片路径 */
  opacity: 0.1; /* 控制背景图透明度 */
  z-index: 0; /* 确保背景图在内容后面 */
  pointer-events: none; /* 避免背景图阻挡点击事件 */
  }
  
  .container{
    width: 800px; /* 固定容器宽度 */
    margin-bottom: 40px;
    padding: 20px; /* 添加容器的内边距 */
    background-color: white; /* 如果需要为容器设置背景色 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 为容器添加阴影 */
    border-radius: 8px; /* 添加圆角 */
  }
  

  
  h1, h2 {
    color: #2c3e50;
  }
  
  h1 {
    margin-bottom: 10px;
  }
  
  h2 {
    margin-top: 20px;
  }
  
  p {
    margin-bottom: 15px;
    line-height: 1.6;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  
  a {
    color: #e74c3c;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  </style>
  